import React from 'react'
import Form from '../components/Form'
import car from '../assets/form_cars.png'
import taxi from "../assets/images/taxiicon.png";
import aeroplane from "../assets/images/aeroplaneicon.png";
import round from "../assets/images/roundIcon.png";
import tourPackage from "../assets/images/tour-guide-icon.png";
import { FiUserCheck } from "react-icons/fi";
import Vision from "../components/Home/Vision";
import Look from '../components/Look';
import About from '../components/About';
import { SlArrowRight } from "react-icons/sl";


const AboutUs = () => {
  return (
    <>
        <div className=" relative bg-zinc-800  flex justify-center items-center px-3">
    <div className=' py-16 md:py-28 lg:py-28' >
      <strong className="text-white text-7xl">About Us</strong>
      <span className='flex gap-x-2 items-center text-white cursor-pointer mt-10 text-xl text-center'>
        <a href="/">Home </a><SlArrowRight />
      About us</span>

    </div>
    </div>

    <About />
      {/* <div className="form_bg relative bg-fixed flex justify-center px-3">
        <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
          <Form />
        </div>
      </div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h1 className="text-center text-6xl font-semibold mt-5">
          About Us
        </h1>
        <div className=' mt-10 flex-col items-center justify-between'>

          <p className='text-lg'>Welcome to Zero Taksi, your trusted car rental and outstation taxi service in Madurai, Tamil Nadu. Whether you're exploring the vibrant streets of Madurai or planning a long journey to discover the hidden gems of Tamil Nadu, Jolly Cabsis here to make your travel comfortable, reliable, and hassle-free.
          </p>
          <img src={car} />
        </div>
        <h1 className="text-center text-6xl font-semibold mt-5">
          Who We Are
        </h1>
        <div className=' mt-10 flex-col items-center justify-between'>

          <p className='text-lg'>
            Jolly Cabswas founded with a mission to provide top-notch transportation solutions tailored to meet the diverse needs of our customers. Our fleet consists of well-maintained vehicles ranging from compact cars to spacious SUVs, ensuring that you find the perfect ride for your journey.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-3 my-14">
          <div className="flex flex-col bg-[#2A2F3C] rounded-lg p-4 px-10 justify-between gap-y-3">
            <div className="flex justify-center">
              <img src={taxi} className="w-[60%]" />
            </div>
            <span className="font-bold text-2xl text-white text-center">Local Car Rentals</span>
            <p className="text-center text-white">
              Navigating Madurai has never been easier. With our local car rental service, you can explore the city at your own pace. Whether it's a day of sightseeing, a business meeting, or running errands, we have you covered.
            </p>
          </div>
          <div className="flex flex-col bg-[#2A2F3C] rounded-lg p-4 px-10 justify-between">
            <div className="flex justify-center">
              <img src={tourPackage} className="w-[60%]" />
            </div>
            <span className="font-bold text-2xl text-white text-center"> Outstation Taxi Service
            </span>
            <p className="text-center text-white">
              Planning a trip outside Madurai? Our outstation taxi service is designed to provide you with a seamless travel experience. Whether it's a weekend getaway, a pilgrimage, or a business trip, we ensure you reach your destination safely and comfortably.
            </p>
          </div>

        </div>
      </div>

      <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16 mt-5' >
      <h1 className="text-center text-4xl font-semibold ">
      Why Choose Zero Taksi? 
      </h1>

        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-5 lg:mt-20'>
          <div className='flex flex-col justify-between gap-y-5 hover:bg-black hover:text-white p-3 rounded-lg shadow-lg'>
            <div className='bg-[#EDB701] flex justify-center rounded-lg p-3 hover:bg-white hover:text-black '><FiUserCheck size={25} className='' /> </div>
            <h2 className='text-center text-2xl font-bold hover:text-white '>Professional Drivers</h2>
            <p className='text-center opacity-90 hover:text-white'>Our drivers are experienced, courteous, and well-versed with the routes, ensuring a smooth and enjoyable journey.</p>
          </div>
          <div className='flex flex-col justify-between gap-y-5 hover:bg-black hover:text-white p-3 rounded-lg shadow-lg'>
            <div className='bg-[#EDB701] flex justify-center rounded-lg p-3 hover:bg-white hover:text-black '><FiUserCheck size={25} className='' /> </div>
            <h2 className='text-center text-2xl font-bold hover:text-white '>Affordable Rates</h2>
            <p className='text-center opacity-90 hover:text-white'>We offer competitive pricing without compromising on quality, making our services accessible to everyone.</p>
          </div>
          <div className='flex flex-col justify-between gap-y-5 hover:bg-black hover:text-white p-3 rounded-lg shadow-lg'>
            <div className='bg-[#EDB701] flex justify-center rounded-lg p-3 hover:bg-white hover:text-black '><FiUserCheck size={25} className='' /> </div>
            <h2 className='text-center text-2xl font-bold hover:text-white '>24/7 Availability</h2>
            <p className='text-center opacity-90 hover:text-white'>Whether you need a ride early in the morning or late at night, our services are available round the clock.</p>
          </div>
          <div className='flex flex-col justify-between gap-y-5 hover:bg-black hover:text-white p-3 rounded-lg shadow-lg'>
            <div className='bg-[#EDB701] flex justify-center rounded-lg p-3 hover:bg-white hover:text-black '><FiUserCheck size={25} className='' /> </div>
            <h2 className='text-center text-2xl font-bold hover:text-white '>Easy Booking </h2>
            <p className='text-center opacity-90 hover:text-white'>Our user-friendly booking process ensures that reserving a car is quick and simple, whether online or over the phone.</p>
          </div>

        </div>
        <h1 className="text-center text-4xl font-semibold mt-10 lg:mt-24">
        Our Commitment 
        </h1>
         <p className='text-center text-lg mt-5'>At Zero Taksi, customer satisfaction is our top priority. We strive to provide a reliable and enjoyable travel experience, ensuring that every journey with us is memorable. Your safety and comfort are paramount, and we go the extra mile to deliver a service that exceeds your expectations.
         </p>
         <p className='font-bold text-lg mt-5 text-center'>Thank you for choosing Zero Taksi. We look forward to being a part of your journey in and around Tamil Nadu.</p>
      </div> */}
      <Look />
    </>

  )
}

export default AboutUs
