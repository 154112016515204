import React from 'react'
import bg from "../assets/images/LookBg.jpg";
const Look = () => {
    const divStyle={
        backgroundImage: `url( ${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat:"no-repeat"
    
      }
    
    return (
        <div className='container md:py-16 mt-4 px-6 md:px-14 -mb-[100px] relative z-[1] ' >
            <div className=' mx-auto   py-4 sm:py-6 md:py-8 lg:py-10 px-4 rounded-md' >
                <div className='grid grid-cols-1 lg:grid-cols-2'>
                <div className='flex flex-col items-center gap-x-5 gap-y-2 flex-wrap md:flex-nowrap text-white md:px-8 justify-center md:justify-normal'>
                  <strong className='text-black text-4xl'>Contact Us To Book A Taxi</strong>
                    <a href="tel:+917373300883" className='th-btn-reverse look-btn mt-2'>CALL +91 7373300883 </a>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Look