import React from "react";
import banner from "../assets/images/backgroundTaxi.jpg";
import Form from "./Form";
import { FaPhone } from "react-icons/fa6";
import FormDuplicate from "./FormDuplicate";
import img1 from "../assets/images/ser1.png";
import img2 from "../assets/images/ser2.png";
import img3 from "../assets/images/ser3.png";
import img4 from "../assets/images/ser4.png";
import callicon from "../assets/images/callnow.gif"
import { Link } from 'react-router-dom';
import headimg from '../assets/images/headerbackimg.png';
import carCard from "../assets/images/carCard.png";


const BookingForm = () => {
  const divStyle = {
    backgroundImage: `url( ${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat"
    // animation :`( ${animate} ) 1s linear infinite`  ,  

  };

  return (



    <div className=" form_bg relative head_bg " style={divStyle} >
      <div className="container  py-2 mx-auto  px-0 sm:px-4   md:px-8 md:pb-24 lg:px-16 lg:pb-24 relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-y-6 gap-x-10 ">
          <div className="grid grid-cols-1">
            <div className="bg-white  rounded-lg px-4  pb-4 order-1  md:order-0 lg:order-0" id="book_taxi_form">

              <Form />

            </div>
          </div>
          {/* <div className="order-0 md:order-1 lg:order-1">
            <img src={banner}/>
            </div> */}
          <div className="w-full md:w-[90%] lg:mt-20 mx-auto md:ml-auto px-4 sm:px-0">
            {/* <p className=" text-white font-semibold text-lg">
              Book Drop Taxi Service with Riya Drop taxi
            </p> */}
            <div className=" hidden md:block text-md text-white ">Jolly Cabs</div>
            <h3 className="hidden md:block  mt-4 font-bold text-white  text-3xl md:text-5xl  lg:text-5xl ">

            JOlly Cabs
              Superior Quality At Low Cost.
              {" "}
            </h3>

            {/* <div className=" text-6xl text-white font-bold mt-4">
              Welcome to
            </div>
            <div className=" text-6xl text-white font-bold mt-4">
            Riya Drop taxi

 
            </div> */}
            <div className=" mt-4 text-lg  text-white ">
              {" "}

              Since day one, Jolly cabs has been on the road
              to client satisfaction by providing stress-free taxi service.
            </div>
            <div className="mt-3">
                             <a href="tel:+917373300883 " className='th-btn-reverse'>
                                   <span className='flex gap-x-2 items-center '><FaPhone size={17} /> +91 7373300883</span>
                              </a>
                         </div>
          </div>
        </div>
      </div>
    </div>



  );
};

export default BookingForm;
