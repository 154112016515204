import React from 'react'
import { Link } from 'react-router-dom';
import bg from "../../assets/images/zerotaksi-logo.png";
import callicon from "../../assets/images/callnow.gif"
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { TbBrandTwitter } from "react-icons/tb";
import { LuInstagram } from "react-icons/lu";
import { SlSocialLinkedin } from "react-icons/sl";


import FooterRoute from './FooterRoute';

const Footer = () => {
    return (
        <>
            < div className="serv_parent  pt-5 sm:pt-10 md:pt-20 lg:pt-28 mt-10 text-black" >
                <div className=' container mx-auto px-4 md:px-8'>
                    <FooterRoute />
                    <div className="" >
                        <div className='flex flex-col gap-5 items-center' id='contact-us'>
                            <h2 className="text-white text-xl font-bold uppercase">About Company</h2>
                            <p className="text-white text-center">Jolly Cabs has been offering hassle-free taxi services at the highest level since its inception. We are one of the most well-known and dependable taxi/cab services in the area.</p>
                            <div><strong className="text-white">WE ARE AVAILABLE</strong>
                                <p className="text-white">24*7 hours available here</p> </div>
                            <div className=' '>
                                <a href="tel:+917373300883" className='th-btn-reverse '>Contact  Us</a>
                            </div>


                        </div>
                        {/* <div className=' flex flex-col gap-5 md:items-center lg:items-center'>
                            <h2 className="text-white text-xl font-bold uppercase">Quick Link</h2>
                            <div className=' flex flex-col gap-5 '>
                            <span className='flex gap-x-2 items-center text-white hover:text-[#e30d16] cursor-pointer'><FaLongArrowAltRight />Booking</span>
                            <span className='flex gap-x-2 items-center text-white hover:text-[#e30d16] cursor-pointer'><FaLongArrowAltRight />About</span>
                            <span className='flex gap-x-2 items-center text-white hover:text-[#e30d16] cursor-pointer'><FaLongArrowAltRight />Pricing</span>
                            <span className='flex gap-x-2 items-center text-white hover:text-[#e30d16] cursor-pointer'><FaLongArrowAltRight />Cars</span>
                            </div>
                        </div>
                        <div className="flex justify-center gap-2">
                        <div className='text-white opacity-75 border w-[40px] h-[40px] flex items-center justify-center rounded-full cursor-pointer hover:bg-red-600 hover:opacity-100'><FaFacebookF /> </div>
                        <div className='text-white opacity-75 border w-[40px] h-[40px] flex items-center justify-center rounded-full cursor-pointer hover:bg-red-600 hover:opacity-100'><TbBrandTwitter />
                        </div>
                        <div className='text-white opacity-75 border w-[40px] h-[40px] flex items-center justify-center rounded-full cursor-pointer hover:bg-red-600 hover:opacity-100'><LuInstagram />                        </div>
                        <div className='text-white opacity-75 border w-[40px] h-[40px] flex items-center justify-center rounded-full cursor-pointer hover:bg-red-600 hover:opacity-100'><SlSocialLinkedin />                        </div>

                        </div> */}
                    </div>
                </div>
            </div>
            <div className=' bg-black  px-4 py-4 sm:py-7'>
                         <ul className=' flex flex-col sm:flex-row justify-center gap-x-4 items-center'>
                             <li><Link to="/terms-and-conditions" className=' block text-[#E4A900] hover:text-white transition-all'>Terms & Conditions</Link></li>
                             <li><Link to="/privacy-policy" className=' block text-[#E4A900] hover:text-white transition-all'>Privacy Policy</Link></li>
                         </ul>
                     </div>
            <div className='py-4 sm:py-7 text-white bg-[#2a2f3c] text-center px-4'>
                Copyright @ 2024 <Link className='text-[#E4A900] hover:text-[#fff] transition-all'>Jolly Cabs

                </Link>. All Rights Reserved.Designed by <Link to="https://netbixzie.in/" target="_blank" className='text-[#E4A900] hover:text-[#fff] transition-all'>
                    Netbix Technologies </Link>
            </div>
        </>

        //         < div className="serv_parent  pt-5 sm:pt-10 md:pt-20 lg:pt-28 mt-10 text-black" >
        //              <div className=' container mx-auto px-4 md:px-8'>
        //                 <div className='flex flex-col justify-between items-center mt-20 md:mt-0 lg:mt-0 xl:mt-0'>
        //                     <div>
        //                       <img src={bg}/>
        //                     </div>
        //                    <Link to="tel:+917373300883">  < img src={callicon} /> </Link>
        //                     {/* <ul className=' mt-5 flex flex-col gap-y-3 md:gap-y-6'>
        //                                     <li><a href="tel:+917373300883"  className=' flex gap-x-2 items-center w-full group  '><span className=' flex-shrink-0 w-[30px] h-[30px] primary-color text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><FaPhone   /></span><span className=' text-white group-hover:text-[#E4A900]'>+91 7373300883</span></a></li>
        //                                     <li className=' flex gap-x-2 items-center'><a href='jollycabs657@gmail.com'  className=' flex gap-x-2 items-center w-full group '><span className=' flex-shrink-0 w-[30px] h-[30px] primary-color text-[14px] bg-[#ffffff26] rounded-md flex justify-center   items-center'><IoMail /></span><span className=' text-white group-hover:text-[#E4A900]'>jollycabs657@gmail.com</span></a></li>
        //                                 </ul> */}

        //                 </div>
        //                 <div className=' flex gap-x-2 md:gap-x-5 justify-between flex-wrap lg:flex-nowrap gap-y-4 mt-16'>
        //                         <div className=' w-full md:w-full lg:w-[25%] text-[#BDBDBD]'>
        //                                 <p>Jolly Cabs

        //   providing reliable and convenient one-way taxi services throughout Tamil Nadu, Pondicherry, Bangalore, Hyderabad, and Kochi. Experience the convenience and reliability of our services. Contact us today!</p>
        //                         </div>
        //                         {/* <div className=' w-full md:w-full lg:w-[25%] text-[#BDBDBD]' id='contact'>
        //                                 <h3 className=' font-semibold text-xl md:text-2xl text-white'>Contact Us</h3>
        //                                 <ul className=' mt-5 flex flex-col gap-y-3 md:gap-y-6'>
        //                                     <li className=' flex gap-x-2 items-center'><span className=' flex-shrink-0 w-[30px] h-[30px] primary-color text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><FaLocationDot /></span><span>Coimbatore Tamil Nadu
        // </span></li>
        //                                     <li><a href="tel:+917373300883"  className=' flex gap-x-2 items-center w-full group  '><span className=' flex-shrink-0 w-[30px] h-[30px] primary-color text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><FaPhone   /></span><span className='group-hover:text-[#E4A900]'>+91 7373300883</span></a></li>
        //                                     <li className=' flex gap-x-2 items-center'><a href='jollycabs657@gmail.com'  className=' flex gap-x-2 items-center w-full group '><span className=' flex-shrink-0 w-[30px] h-[30px] primary-color text-[14px] bg-[#ffffff26] rounded-md flex justify-center items-center'><IoMail /></span><span className='group-hover:text-[#E4A900]'>jollycabs657@gmail.com</span></a></li>
        //                                 </ul>
        //                         </div> */}
        //                         <div className=' w-full md:w-full lg:w-[50%] text-[#BDBDBD]'>
        //                         <h3 className=' font-semibold text-xl md:text-2xl text-[#E4A900]'>One Way Taxi Cities</h3>
        //                         <ul className=' mt-5 grid grid-cols-1 gap-x-2 gap-y-3  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3'>
        //                             <ul className=' flex flex-col gap-y-3'>
        //                                 <Link className='text-white hover:text-[#E4A900] transition-all'>Chennai</Link>
        //                                 <Link className='text-white hover:text-[#E4A900] transition-all'>Coimbatore</Link>
        //                                 <Link className='text-white hover:text-[#E4A900] transition-all'>Bangalore</Link>
        //                             </ul>
        //                             <ul className=' flex flex-col gap-y-3'>
        //                                 <Link className='text-white hover:text-[#E4A900] transition-all'>Salem</Link>
        //                                 <Link className='text-white hover:text-[#E4A900] transition-all'>Madurai</Link>
        //                                 <Link className='text-white hover:text-[#E4A900] transition-all'>Pondicherry</Link>
        //                             </ul>
        //                             <ul className=' flex flex-col gap-y-3'>
        //                                 <Link className='text-white hover:text-[#E4A900] transition-all'>Vellore</Link>
        //                                 <Link className='text-white hover:text-[#E4A900] transition-all'>Trichy</Link>
        //                                 <Link className='text-white hover:text-[#E4A900] transition-all'>Kumbakonam</Link>
        //                             </ul>
        //                         </ul>

        //                         </div>
        //                 </div>
        //             </div>
        //             <div className=' bg-black mt-3 sm:mt-5 md:mt-8 px-4 py-4 sm:py-7'>
        //                 <ul className=' flex flex-col sm:flex-row justify-center gap-x-4 items-center'>
        //                     <li><Link to="/terms-and-conditions" className=' block text-[#E4A900] hover:text-white transition-all'>Terms & Conditions</Link></li>
        //                     <li><Link to="/privacy-policy" className=' block text-[#E4A900] hover:text-white transition-all'>Privacy Policy</Link></li>
        //                 </ul>
        //             </div>
        //             <div className='py-4 sm:py-7 text-white bg-[#2a2f3c] text-center px-4'>
        //             Copyright @ 2024 <Link className='text-[#E4A900] hover:text-[#fff] transition-all'>Jolly Cabs

        //  </Link>. All Rights Reserved.Designed by <Link to="https://netbixzie.in/" target="_blank"className='text-[#E4A900] hover:text-[#fff] transition-all'> 
        //      Netbix Technologies
        // </Link>
        //             </div>
        //         </div>
    )
}

export default Footer